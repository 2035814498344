/**
 *
 * @param {*} links Array of links
 */
export default function (links) {
  const i18nHead = useLocaleHead({
    addSeoAttributes: true,
    identifierAttribute: "seo-can",
  });

  let linksToBuildFrom = i18nHead.value?.link;

  if (links) {
    linksToBuildFrom = links;
  }

  useHead({
    link: [
      ...(linksToBuildFrom.filter((link) => link.rel === "canonical") ?? []),
    ],
  });
}
